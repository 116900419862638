import { Box, Container, Link, Stack, Typography } from '@mui/material'

import { alpha } from '@mui/material';

export default function HeroImage() {
    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundImage:
                    theme.palette.mode === 'light'
                        ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                        : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 10, sm: 12 },
                    pb: { xs: 14, sm: 16 },
                }}
            >
                <Stack spacing={2} useFlexGap>
                    <Typography
                        variant="h1"
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            alignSelf: 'center',
                            textAlign: 'center',
                            fontSize: 'clamp(3.5rem, 10vw, 4rem)',
                        }}
                    >
                        Roth-Steyr M1907 Database
                    </Typography>
                </Stack>

                <Box
                    id="image"
                    sx={(theme) => ({
                        mt: { xs: 2, sm: 4 },
                        alignSelf: 'center',
                        height: { xs: 200, sm: 700 },
                        width: '100%',
                        backgroundImage: 'url("/images/header.webp")',
                        backgroundSize: 'cover',
                        borderRadius: '10px',
                        outline: '1px solid',
                        outlineColor:
                            theme.palette.mode === 'light'
                                ? alpha('#BFCCD9', 0.5)
                                : alpha('#9CCCFC', 0.1),
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                    })}
                />

                <Typography align='center' paragraph variant="subtitle1" color="text.primary" sx={{ fontSize: 14 }}>
                    Officers of K.u.K. Infantry Regiment "Grand Duke of Hesse" Nr. 14 practicing with M.7 pistols
                    <Typography variant="subtitle1" color="text.primary" sx={{ fontStyle: 'oblique', fontSize: 12 }}>Image from <Link target="_blank" rel="noopener" href="https://www.reddit.com/r/MilitaryPorn/comments/s59xnb/austrohungarian_officers_at_the_shooting_range/">Reddit</Link></Typography>
                </Typography>
            </Container>
        </Box>
    );
}
