import React from 'react';
import axios from 'axios';

import { Box, Divider, useMediaQuery } from '@mui/material';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import AppAppBar from './components/AppBar';
import HeroImage from './components/HeroImage';

import About from './components/About';
import Database from './components/Database';
import Footer from './components/Footer';
import Marks from './components/Marks';
import Units from './components/Units';

export default function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [mode, setMode] = React.useState(prefersDarkMode ? "dark" : "light");

  const [manufacturers, setManufacturers] = React.useState([]);
  const [acceptanceMarks, setAcceptanceMarks] = React.useState([]);
  const [unitMarkings, setUnitMarkings] = React.useState([]);

  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  React.useEffect(() => {
    axios.get("/api/manufacturers")
      .then(res => {
        setManufacturers(res.data.results);
      })
      .catch(err => console.log(err));
  }, []);

  React.useEffect(() => {
    axios.get("/api/acceptance")
      .then(res => {
        setAcceptanceMarks(res.data.results);
      })
      .catch(err => console.log(err));
  }, []);

  React.useEffect(() => {
    axios.get("/api/units")
      .then(res => {
        setUnitMarkings(res.data.results);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <React.Fragment>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
        <HeroImage />
        <Box sx={{ bgcolor: 'background.default' }}>
          <Divider />
          <Database manufacturers={manufacturers} acceptanceMarks={acceptanceMarks} unitMarkings={unitMarkings} />
          <Divider />
          <Units unitMarkings={unitMarkings} />
          <Divider />
          <Marks acceptanceMarks={acceptanceMarks} />
          <Divider />
          <About />
          <Footer />
        </Box>
      </ThemeProvider>
    </React.Fragment>
  );
}
