import { Box, Container, IconButton, Link, Stack, Typography } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';

export default function Footer() {
    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 4, sm: 8 },
                py: { xs: 8, sm: 10 },
                textAlign: { sm: 'center', md: 'left' },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    pt: { xs: 4, sm: 8 },
                    width: '100%',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <div>
                    <Typography color="text.secondary" display="inline">
                        Adapted from the MUI
                        <Link href="https://github.com/mui/material-ui/tree/v5.15.15/docs/data/material/getting-started/templates/landing-page" sx={{ mx: 0.5, opacity: 0.5 }}>
                            Landing Page
                        </Link>
                        template
                        &nbsp;•&nbsp;
                        Hosted using
                        <Link href="https://pages.cloudflare.com/" sx={{ mx: 0.5, opacity: 0.5 }}>
                            Cloudflare Pages
                        </Link>
                        and
                        <Link href="https://developers.cloudflare.com/d1/" sx={{ mx: 0.5, opacity: 0.5 }}>
                            Cloudflare D1
                        </Link>
                        &nbsp;•&nbsp;
                        Icon created by
                        <Link href="https://commons.wikimedia.org/wiki/File:Roth_Steyr_M1907.svg" sx={{ mx: 0.5, opacity: 0.5 }}>
                            Atirador
                        </Link>
                    </Typography>

                    <Typography variant="body2" color="text.secondary" mt={1}>
                        {'Copyright © Roth-Steyr DB '}{new Date().getFullYear()}
                    </Typography>
                </div>
                <Stack
                    direction="row"
                    justifyContent="left"
                    spacing={1}
                    useFlexGap
                    sx={{
                        color: 'text.secondary',
                    }}
                >
                    <IconButton
                        color="inherit"
                        href="mailto:email@example.com"
                        aria-label="Email"
                        sx={{ alignSelf: 'center' }}
                    >
                        <EmailIcon />
                    </IconButton>
                </Stack>
            </Box>
        </Container>
    );
}
