import axios from 'axios';
import React from 'react';
import { Box, Container, LinearProgress, Tab, Tabs, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export default function Database(props) {
    const { manufacturers, acceptanceMarks, unitMarkings } = props;

    const [selectedTab, setSelectedTab] = React.useState("all");

    const [allSerialPistols, setAllSerialPistols] = React.useState([]);
    const [steyrSerialPistols, setSteyrSerialPistols] = React.useState([]);
    const [fegSerialPistols, setFegSerialPistols] = React.useState([]);
    const [unknownSerialPistols, setUnknownSerialPistols] = React.useState([]);

    React.useEffect(() => {
        if (selectedTab === "all" && allSerialPistols.length === 0) {
            axios.get("/api/serials")
            .then(res => {
                setAllSerialPistols(res.data.results);
            })
            .catch(err => console.log(err));
        }
        if (selectedTab === "steyr" && steyrSerialPistols.length === 0) {
            axios.get("/api/serials/1")
            .then(res => {
                setSteyrSerialPistols(res.data.results);
            })
            .catch(err => console.log(err));
        }
        if (selectedTab === "feg" && fegSerialPistols.length === 0) {
            axios.get("/api/serials/2")
            .then(res => {
                setFegSerialPistols(res.data.results);
            })
            .catch(err => console.log(err));
        }
        if (selectedTab === "unknown" && unknownSerialPistols.length === 0) {
            axios.get("/api/serials/3")
            .then(res => {
                setUnknownSerialPistols(res.data.results);
            })
            .catch(err => console.log(err));
        }
    }, [selectedTab, allSerialPistols, steyrSerialPistols, fegSerialPistols, unknownSerialPistols]);

    return (
        <Container id="database" sx={{
            py: { xs: 4, sm: 8 },
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
        }}>
            <Box
                sx={{
                    width: { sm: '100%', md: '100%' },
                    textAlign: 'justify',
                }}
            >
                <Typography component="h2" variant="h4" color="text.primary" sx={{ textAlign: 'center', mb: 2 }}>
                    Database
                </Typography>

                <Tabs centered value={selectedTab} onChange={(e, s) => setSelectedTab(s)}>
                    <Tab label="All Pistols" value="all" />
                    <Tab label="Waffenfabrik Steyr" value="steyr" />
                    <Tab label="Fegyvergyár Budapest" value="feg" />
                    <Tab label="Unknown Manufacturer" value="unknown" />
                </Tabs>

                <Typography sx={{ mt: 2 }}>
                    The M1907 database is a record of all observed examples of M1907 pistols, their acceptance marks, unit marks and
                    whether they have the disconnector pin implemented to prevent double firing. Examples here have been sourced from
                    literature, auction and sales listings, videos, and both private and national collections.
                </Typography>

                {selectedTab === "all" && GetTable(allSerialPistols, manufacturers, acceptanceMarks, unitMarkings, true)}
                {selectedTab === "steyr" && GetTable(steyrSerialPistols, manufacturers, acceptanceMarks, unitMarkings, false)}
                {selectedTab === "feg" && GetTable(fegSerialPistols, manufacturers, acceptanceMarks, unitMarkings, false)}
                {selectedTab === "unknown" && GetTable(unknownSerialPistols, manufacturers, acceptanceMarks, unitMarkings, false)}
            </Box>
        </Container>
    );
}

function GetTableColumns(manufacturers, acceptanceMarks, unitMarkings, manufacturerColumn) {
    const getAcceptanceMarks = (value) => {
        const marks = acceptanceMarks.find((e) => e.id === value);
        if (marks) {
            return marks.acceptance_marks;
        }
        return ""
    };

    const columns = [
        { field: 'serial_number', headerName: 'Serial Number', width: 125 },
        { field: 'military_acceptance', headerName: 'Acceptance Marks', width: 135, valueFormatter: getAcceptanceMarks },
        { field: 'reissue_military_acceptance', headerName: 'Re-issue Acceptance Marks', width: 200, valueFormatter: getAcceptanceMarks },
        {
            field: 'disconnector_pin',
            headerName: 'Disconnector Pin',
            width: 125,
            valueFormatter: (value) => {
                if (value === 0) {
                    return "❌";
                }
                if (value === 1) {
                    return "✔️";
                }
                return "❔";
            },
        },
        {
            field: 'unit_mark_id',
            headerName: 'Unit',
            width: 250,
            valueFormatter: (value) => {
                const unitMarking = unitMarkings.find((e) => e.id === value);
                if (unitMarking) {
                    return unitMarking.full_name;
                }
                return ""
            },
        },
        { field: 'weapon_number', headerName: 'Weapon Number', width: 125 },
        { field: 'raw_unit_mark', headerName: 'Raw Unit Marking', width: 125 },
        { field: 'notes', headerName: 'Notes', width: 750 },
        { field: 'date_added', headerName: 'Date Added', width: 100 },
        { field: 'source', headerName: 'Source(s)', width: 1250 },
    ];

    if (manufacturerColumn) {
        return [{
            field: 'manufacturer',
            headerName: 'Manufacturer',
            width: 175,
            valueFormatter: (value) => {
                const manufacturer = manufacturers.find((e) => e.id === value);
                if (manufacturer) {
                    return manufacturer.manufacturer;
                }
                return "";
            },
        }].concat(columns);
    }
    return columns;
}

function GetTable(data, manufacturers, acceptanceMarks, unitMarkings, showManufacturer) {

    return (
        <React.Fragment>
            <div style={{ width: '100%', height: "70vh" }}>
                <DataGrid
                    rows={data}
                    columns={GetTableColumns(manufacturers, acceptanceMarks, unitMarkings, showManufacturer)}
                    sx={{ my: 3 }}
                    slots={{ loadingOverlay: LinearProgress }}
                    loading={data.length === 0}
                    pageSizeOptions={[10]}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                        },
                    }}
                />
            </div>
        </React.Fragment>
    );
}
