import React from 'react';
import { Box, Container, LinearProgress, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    { field: 'full_name', headerName: 'Full Name', flex: 2 },
    { field: 'identifier', headerName: 'Unit Marking', flex: 1 },
    { field: 'notes', headerName: 'Notes', flex: 2 },
];

export default function Units(props) {
    const { unitMarkings } = props;

    return (
        <Container id="units" sx={{
            py: { xs: 4, sm: 8 },
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
        }}>
            <Box
                sx={{
                    width: { sm: '100%', md: '100%' },
                    textAlign: 'justify',
                }}
            >
                <Typography component="h2" variant="h4" color="text.primary" sx={{ textAlign: 'center', mb: 2 }}>
                    Austro-Hungarian Unit Markings
                </Typography>

                <Typography>
                    M1907 pistols were commonly marked with the unit that they were assigned to, enabling unique insight into the intended users of this pistol.
                    Designed with a heavy trigger pull to act as a safety mechanism for cavalry, it was also issued to field artillery, fortress artillery, infantry,
                    Landwehr, and engineers. When items were reassigned to different units, these markings were commonly overstamped.
                </Typography>

                <div style={{ width: '100%', height: "57vh" }}>
                    <DataGrid
                        rows={unitMarkings}
                        columns={columns}
                        sx={{ my: 3 }}
                        slots={{ loadingOverlay: LinearProgress }}
                        loading={unitMarkings.length === 0}
                    />
                </div>
            </Box>
        </Container>
    );
}
