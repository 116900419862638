import React from 'react';
import { Box, Container, LinearProgress, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    { field: 'acceptance_marks', headerName: 'Acceptance Mark', flex: 1 },
    { field: 'notes', headerName: 'Notes on Acceptance Mark', flex: 2 },
];

export default function Marks(props) {
    const { acceptanceMarks } = props;

    return (
        <Container id="marks" sx={{
            py: { xs: 4, sm: 8 },
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
        }}>
            <Box
                sx={{
                    width: { sm: '100%', md: '100%' },
                    textAlign: 'justify',
                }}
            >
                <Typography component="h2" variant="h4" color="text.primary" sx={{ textAlign: 'center', mb: 2}}>
                    Military Acceptance Marks
                </Typography>

                <Typography>
                    Austro-Hungarian military acceptance marks are a useful source of identifying when a M1907 was produced by one of the two manufacturers,
                    and when they were produced by the two manufacturers. Waffenfabrik Steyr produced the M1907 from 1909 to 1913, with Fegyvergyár Budapest
                    producing the M1907 from 1911 until 1914. These were marked either Wn (Wien) or Lw (Landwehr) with a two digit year suffix.
                    Guns which were re-accepted into military service often come with additional acceptance marks, sometimes stamped over the original markings.
                    These markings were typically Wn 25 / 26 in 1925-26, or Hv for Heeresverwaltung from 1929 until 1938 when Austria was annexed by Nazi Germany.
                </Typography>

                <div style={{ width: '100%', height: "55vh" }}>
                    <DataGrid
                    rows={acceptanceMarks}
                    columns={columns}
                    sx={{ my: 3 }}
                    slots={{ loadingOverlay: LinearProgress }}
                    loading={acceptanceMarks.length === 0}
                />
                </div>
            </Box>
        </Container>
    );
}
