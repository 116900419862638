import axios from 'axios';
import React from 'react';
import { alpha, Box, Card, CardContent, Container, Grid, LinearProgress, Link, Skeleton, Tooltip, Typography } from '@mui/material';

import LiteYouTubeEmbed from 'react-lite-youtube-embed';

import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import NotesIcon from '@mui/icons-material/Notes';
import SearchIcon from '@mui/icons-material/Search';

import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import './About.css';

export default function About() {

    const [overview, setOverview] = React.useState(null);

    React.useEffect(() => {
        axios.get("/api/overview")
            .then(res => {
                setOverview(res.data);
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <Container id="about" sx={{
            py: { xs: 10, sm: 14 }, minHeight: "100vh", position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
        }}>
            <Box
                sx={{
                    width: { sm: '100%', md: '80%' },
                    textAlign: 'justify',
                }}
            >
                <Typography component="h2" variant="h4" color="text.primary" sx={{ textAlign: 'center', mb: 2 }}>
                    About the M1907
                </Typography>
                <Typography color="text.primary">
                    The Roth-Steyr M1907, also known as the Roth-Krnka or Repetierpistole M.7, was among one of the first semi-automatic pistols to be adopted by a major military - the Austro-Hungarian empire - in 1907.
                    Produced from 1909 through to 1914, this pistol saw use with cavalry, field and mountain artillery, machine gun, naval and infantry units throughout the First World War.
                    Manufacturing was split between two major commercial concerns: Waffenfabrik Steyr and Fegyvergyár Budapest, with 59,334 produced by Steyr
                    and 38,213 produced by FEG - with commercial production too - for approximately 98,000 being produced.
                </Typography>
                <br />
                <Typography color="text.primary">
                    These pistols were still in use in the interwar period and Second World War, with Austria-Hungary using them as reparation payments,
                    some examples being accepted by the Austrian Army before the Anschluss,
                    as well as being issued by the Nazi German Customs Service (Reichsfinanzverwaltung).
                    Many examples were taken as trophies of war after the Second World War, which proves that many examples were still in German and Austrian hands
                    more than 30 years after their production.
                </Typography>
                <br />
                <Typography color="text.primary">
                    The M1907 is a striker fired pistol using a locked-breach short-recoil rotating barrel operating system.
                    Rather than the conventional box magazine system that is common in modern handguns, the M1907 is fed using a stripper clip into an internal magazine holding 10 rounds.
                    Firing the 8x19mm Roth-Steyr / Steyr cartridge, it had a heavy trigger pull to prevent accidental discharge by mounted cavalry caused by a skittish horse.
                    Upon discharging the final round in the magazine, the bolt would catch on the internal magazine follower, allowing the user to reload using the stripper clip.
                    The same stripper-clip fed internal magazine system and cartridge ejection system can be seen in Steyr's subsequent pistol, the Steyr-"Hahn" M1912.
                </Typography>
                <Box
                    id="image"
                    sx={(theme) => ({
                        mt: { xs: 2, sm: 4 },
                        alignSelf: 'center',
                        height: { xs: 200, sm: 700 },
                        width: '100%',
                        backgroundImage: 'url("/images/stripper-clip.webp")',
                        backgroundSize: 'cover',
                        borderRadius: '10px',
                        outline: '1px solid',
                        outlineColor:
                            theme.palette.mode === 'light'
                                ? alpha('#BFCCD9', 0.5)
                                : alpha('#9CCCFC', 0.1),
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                    })}
                />
                <Typography align='center' paragraph variant="subtitle1" color="text.primary" sx={{ fontSize: 14 }}>
                    Original 8mm ammunition packaged on the unique 10 round stripper clip
                    <Typography variant="subtitle1" color="text.primary" sx={{ fontStyle: 'oblique', fontSize: 12 }}>Image from jonnyc on <Link target="_blank" rel="noopener" href="https://forum.cartridgecollectors.org/t/8mm-roth-steyr-box/22668">Cartridge Collectors</Link></Typography>
                </Typography>
                <Typography>
                    For a more in-depth examiniation of the creators, development and usage of the M1907,
                    please check out the excellent and definitive video embedded below by Othias and Mae at C&Rsenal.
                    For comprehensive literature on the M1907, please visit <Link target="_blank" rel="noopener" href="https://waffenbuecher.com/oesterreichische-pistolen-3-baende-von-moetz-und-schuy/oesterr-pistolen-bd-1/">Waffenbücher</Link> for
                    the extremely in-depth book <abbr title="From the origin of the selfloading pistol">Vom Ursprung der Selbstladepistole</abbr>
                    by Josef Mötz and Joschi Schuy, a source which C&Rsenal derived much of their information on the development and use of the M1907 from.
                    Featuring the development of a wide variety of Austro-Hungarian self-loading pistols, it is a must-have for any Austo-Hungarian or First World War small arms collector.
                </Typography>
                <Box sx={{ my: 1 }}>
                    <div className="video-responsive">
                        <LiteYouTubeEmbed
                            id="MQTexxPSszU"
                            title="History of WWI Primer 026: Austro-Hungarian Roth-Krnka M.7 Pistol Documentary"
                        />
                    </div>
                </Box>
                <Typography variant="h4" color="text.primary" sx={{ textAlign: 'center', my: 2 }}>
                    About the Database
                </Typography>

                <Grid container spacing={2} sx={{ textAlign: 'center', my: 3, justifyContent: "space-between" }}>
                    <Grid item xs={12} sm={4}>
                        <Card>
                            <CardContent>
                                <SearchIcon style={{ fontSize: 60 }} />
                                <Typography variant='h4' color="text.primary" gutterBottom>
                                    {overview === null ? <Skeleton /> : overview.acceptance_marks}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                                    Unique Acceptance Marks
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card>
                            <CardContent>
                                <NotesIcon style={{ fontSize: 60 }} />
                                <Typography variant='h4' color="text.primary" gutterBottom>
                                    {overview === null ? <Skeleton /> : overview.pistols}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                                    Pistols in the Database
                                </Typography>
                            </CardContent>
                            {overview !== null &&
                                <Tooltip title={`Approximately ${((overview.pistols / 98000) * 100).toFixed(3)}% of pistols in the database`}>
                                    <LinearProgress variant="determinate" value={((overview.pistols / 98000) * 100).toFixed(3)} />
                                </Tooltip>
                            }
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card>
                            <CardContent>
                                <MilitaryTechIcon style={{ fontSize: 60 }} />
                                <Typography variant='h4' color="text.primary" gutterBottom>
                                    {overview === null ? <Skeleton /> : overview.units}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                                    Unique Unit Markings
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Typography>
                    This database was created based on the inspiration
                    by <Link target="_blank" rel="noopener" href="https://www.lugerforums.com/threads/m7-roth-steyr-data-base.3853/">Jan C. Still's Lugerforums thread</Link> as well as
                    the information collated by Mötz and Schuy in their book "Vom Ursprung der Selbstladepistole". This resource was created using contributions from private collectors, images and videos sourced online, as well as
                    information collated from museum and national collections.
                </Typography>
                <Typography variant="h6" color="text.primary" sx={{ textAlign: 'center', my: 2 }}>
                    With thanks to
                </Typography>
                <Grid container sx={{ textAlign: 'center' }}>
                    <Grid item xs={6} sm={4}>
                        Jan C. Still
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        Tim Hawkins
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Link target="_blank" rel="noopener" href="https://waffenbuecher.com/">Josef Mötz</Link>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        Joschi Schuy
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Link target="_blank" rel="noopener" href="https://www.youtube.com/@Candrsenal">Othias and Mae / C&Rsenal</Link>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Link target="_blank" rel="noopener" href="https://www.youtube.com/c/forgottenweapons">Ian McCollum / Forgotten Weapons</Link>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Link target="_blank" rel="noopener" href="https://royalarmouries.org/">Royal Armouries</Link>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Link target="_blank" rel="noopener" href="https://www.iwm.org.uk/">Imperial War Museums</Link>
                    </Grid>
                </Grid>
            </Box>
        </Container >
    );
}
