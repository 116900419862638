import * as React from 'react';
import PropTypes from 'prop-types';

import { AppBar, Box, Button, Container, Drawer, MenuItem, Toolbar, Typography } from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';

export default function AppAppBar({ mode, toggleColorMode }) {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        const offset = 128;
        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;
            sectionElement.scrollIntoView({ behavior: 'smooth' });
            window.scrollTo({
                top: targetScroll,
                behavior: 'smooth',
            });
            setOpen(false);
        }
    };

    return (
        <div>
            <AppBar
                position="fixed"
                sx={{
                    boxShadow: 0,
                    bgcolor: 'transparent',
                    backgroundImage: 'none',
                    mt: 2,
                }}
            >
                <Container maxWidth="lg">
                    <Toolbar
                        variant="regular"
                        sx={(theme) => ({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexShrink: 0,
                            borderRadius: '999px',
                            bgcolor:
                                theme.palette.mode === 'light'
                                    ? 'rgba(255, 255, 255, 0.4)'
                                    : 'rgba(0, 0, 0, 0.4)',
                            backdropFilter: 'blur(24px)',
                            maxHeight: 40,
                            border: '1px solid',
                            borderColor: 'divider',
                            boxShadow:
                                theme.palette.mode === 'light'
                                    ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                                    : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                        })}
                    >
                        { /* Global toolbar */}
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                alignItems: 'center',
                                ml: '-18px',
                                px: 0,
                            }}
                        >
                            <Typography variant="subtitle1" color="text.primary" sx={{ py: '6px', px: '12px' }}>Roth-Steyr DB</Typography>
                            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <MenuItem onClick={() => scrollToSection('database')} sx={{ py: '6px', px: '12px' }}>
                                    <Typography variant="body2" color="text.primary">Database</Typography>
                                </MenuItem>
                                <MenuItem onClick={() => scrollToSection('units')} sx={{ py: '6px', px: '12px' }}>
                                    <Typography variant="body2" color="text.primary">Unit Markings</Typography>
                                </MenuItem>
                                <MenuItem onClick={() => scrollToSection('marks')} sx={{ py: '6px', px: '12px' }}>
                                    <Typography variant="body2" color="text.primary">Acceptance Marks</Typography>
                                </MenuItem>
                                <MenuItem onClick={() => scrollToSection('about')} sx={{ py: '6px', px: '12px' }}>
                                    <Typography variant="body2" color="text.primary">About</Typography>
                                </MenuItem>
                            </Box>
                        </Box>
                        { /* Desktop toolbar */}
                        <Box
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                gap: 0.5,
                                alignItems: 'center',
                            }}
                        >
                            <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
                        </Box>
                        { /* Mobile toolbar */}
                        <Box sx={{ display: { sm: '', md: 'none' } }}>
                            <Button
                                variant="text"
                                color="primary"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                                sx={{ minWidth: '30px', p: '4px' }}
                            >
                                <MenuIcon />
                            </Button>
                            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                                <Box
                                    sx={{
                                        minWidth: '60dvw',
                                        p: 2,
                                        backgroundColor: 'background.paper',
                                        flexGrow: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'end',
                                            flexGrow: 1,
                                        }}
                                    >
                                        <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
                                    </Box>
                                    <MenuItem onClick={() => scrollToSection('database')}>
                                        Database
                                    </MenuItem>
                                    <MenuItem onClick={() => scrollToSection('units')}>
                                        Unit Markings
                                    </MenuItem>
                                    <MenuItem onClick={() => scrollToSection('marks')}>
                                        Acceptance Marks
                                    </MenuItem>
                                    <MenuItem onClick={() => scrollToSection('about')}>
                                        About
                                    </MenuItem>
                                </Box>
                            </Drawer>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
}

AppAppBar.propTypes = {
    mode: PropTypes.oneOf(['dark', 'light']).isRequired,
    toggleColorMode: PropTypes.func.isRequired,
};

function ToggleColorMode({ mode, toggleColorMode }) {
    return (
      <Box sx={{ maxWidth: '32px' }}>
        <Button
          variant="text"
          onClick={toggleColorMode}
          size="small"
          aria-label="button to toggle theme"
          sx={{ minWidth: '32px', height: '32px', p: '4px' }}
        >
          {mode === 'dark' ? (
            <WbSunnyRoundedIcon fontSize="small" />
          ) : (
            <ModeNightRoundedIcon fontSize="small" />
          )}
        </Button>
      </Box>
    );
  }
